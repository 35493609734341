import { App, Spin, Modal, Button, Typography, Result, theme } from 'antd';

import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Routes, Route, useLocation
} from 'react-router-dom';

import { debounce } from 'lodash';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { openDB } from 'idb';
import iosPWASplash from 'ios-pwa-splash';
import { baseURL, axiosClient } from './apiClient';
import checkToken from './actions/login';
import { applyBulkDiscount } from './functions/applyBulkDiscount';
import { addToCart } from './actions/cart';

import SellerLogin from './components/SellerLogin';
import BuyerLogin from './components/BuyerLogin';
import Catalogo from './pages/Catalogo';
import Checkout from './pages/Checkout';
import Admin from './pages/Admin';

import './App.css';
import logout from './actions/logout';

iosPWASplash('/logo512.png');
const { useToken } = theme;

export default function NuApp() {
  const { message } = App.useApp();
  const { token } = useToken();
  const dispatch = useDispatch();
  const location = useLocation();

  const logged = useSelector((state) => state.authentication.logged);
  const stateMessage = useSelector((state) => state.message);
  const sellerData = useSelector((state) => state.sellerData);
  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const cart = useSelector((state) => state.cart);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const shippingMethod = useSelector((state) => state.order.shippingMethod);
  const syncData = useSelector((state) => state.sync);
  const dataBase = useSelector((state) => state.indexedDB.dataBase);
  const orderRebuyId = useSelector((state) => state.orderRebuy.orderRebuyId);
  const progressiveDiscountRules = useSelector((state) => state.rules.progressiveDiscountRules);
  const themeConfig = useSelector((state) => state.themeConfig);

  const [checkSeller, setCheckSeller] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState('Nupedido');

  const buyerViewRequested = useRef(false);

  const domainName = window.location.hostname;
  const pathName = window.location.pathname.replace(/\//g, '');

  const { Link } = Typography;

  useEffect(() => {
    if (sellerData.blocked && logged && logged !== 'notChecked') {
      logout()
    }
  }, [sellerData.blocked])

  function addQuantityInProduct(product, quantity) {
    const stock = sellerData.orderOutOfStock ? 10000 : product.product_stock;
    const newQty = Math.max(Math.min(quantity, stock), 0);
    return newQty === 0 ? undefined : { ...product, quantity: newQty };
  }

  const bulkAddToCart = (products, quantities) =>
    Promise.all(products.map((product, i) => addQuantityInProduct(product, quantities[i])))
      .then((updatedProducts) => {
        const validProducts = updatedProducts.filter(Boolean);
        dispatch({ type: 'SET_CART', products: validProducts, retrieved: true, returned: true });
        if (validProducts.length) {
          message.success(`Você tem ${validProducts.length} produto(s) no carrinho`, 4);
        }
      });




  useEffect(() => {
    if (logged === 'buyer' && sellerData.orderOutOfStock !== null) {

      axiosClient.get('/cart/get', { withCredentials: true }).then((response) => {
        dispatch({
          type: 'SET_CART_AUX_INFO',
          minOrderValue: response.data.min_order_value,
          cartAuxInfo: response.data.cart_aux_info || {},
        });
        bulkAddToCart(response.data.products, response.data.quantities);
      });
    }
  }, [logged, sellerData.orderOutOfStock]);

  useEffect(() => {
    if (!orderRebuyId &&
      (logged === 'seller' || logged === 'sellerUser')
      && buyerView && sellerData.orderOutOfStock !== null
    ) {
      dispatch({ type: 'RESET_CART' })
      if (syncData.onlineStatus) {
        axiosClient
          .post('/cart/get', { buyerId: buyerView.id }, { withCredentials: true })
          .then((response) => {
            dispatch({
              type: 'SET_CART_AUX_INFO',
              minOrderValue: response.data.min_order_value,
              cartAuxInfo: response.data.cart_aux_info ? response.data.cart_aux_info : {}
            });
            bulkAddToCart(response.data.products, response.data.quantities);
          });
      } else if (syncData.syncStatus === 'synced') {
        getSingleData('carts', buyerView.cart_id).then((cart) => {
          if (cart) {
            getItemsByIds('products', cart.products_ids).then((products) => {
              bulkAddToCart(products.sort((a, b) => cart.products_ids.indexOf(a.id) - cart.products_ids.indexOf(b.id)), cart.quantities)
            });
          } else {
            dispatch({ type: 'SET_CART', returned: true });

          }
        });
      }
    }
  }, [logged, buyerView?.id, orderRebuyId, sellerData.orderOutOfStock]);


  useEffect(() => {
    if (progressiveDiscountRules !== null && cart.retrieved) {
      if (cart.products.length == 0) {
        dispatch({
          type: 'SET_CART',
          productsTotal: 0,
          shippingValue: 0,
          paymentDiscountValue: 0,
          cartTotal: 0,
          productsTotalAfterDiscount: 0,
          retrieved: true
        });
      } else {
        const productsRuleApplied = applyBulkDiscount(progressiveDiscountRules, cart.products);
        const productsTotal = productsRuleApplied.map(amount).reduce(sum);
        const sellerDiscountPercentage = cart.cartAuxInfo.sellerDiscountPercentage;
        let shippingValue = 0;
        let paymentDiscountValue = 0;
        let cartTotal = 0;

        const productsTotalAfterDiscount = sellerDiscountPercentage ? (1 - parseFloat(sellerDiscountPercentage) / 100) * productsTotal : productsTotal;

        if (shippingMethod && shippingMethod.valor) {
          shippingValue = shippingMethod.valor;
        } else {
          shippingValue = 0;
        }

        if (paymentMethod) {
          if (paymentMethod.discount_value) {
            paymentDiscountValue = paymentMethod.discount_type === 'percent'
              ? (parseFloat(paymentMethod.discount_value) / 100) * productsTotalAfterDiscount
              : paymentMethod.discount_value;
          } else if (paymentMethod.metodo === 'pagseguro_credit_card' || paymentMethod.metodo === 'mercadopago_credit_card') {
            paymentDiscountValue = -parseFloat(paymentMethod.aditional_features.fix_tax);
          }
        }

        if (paymentMethod) {
          if (paymentMethod.metodo === 'pagseguro_credit_card') {
            if (paymentMethod.total_value_installment && paymentMethod.installments_selected) {
              cartTotal = parseFloat(parseFloat(paymentMethod.total_value_installment) * parseFloat(paymentMethod.installments_selected)).toFixed(2);
            }
          } else if (paymentMethod.metodo === 'mercadopago_credit_card') {
            cartTotal = parseFloat(parseFloat(productsTotalAfterDiscount) - parseFloat(paymentDiscountValue) + parseFloat(shippingValue)).toFixed(2);
          } else {
            cartTotal = parseFloat(parseFloat(productsTotalAfterDiscount) - parseFloat(paymentDiscountValue) + parseFloat(shippingValue)).toFixed(2);
          }
        } else {
          cartTotal = parseFloat(parseFloat(productsTotalAfterDiscount) - parseFloat(paymentDiscountValue) + parseFloat(shippingValue)).toFixed(2);
        }

        dispatch({
          type: 'SET_CART',
          productsTotal,
          productsTotalAfterDiscount,
          shippingValue,
          paymentDiscountValue,
          cartTotal,
          retrieved: true
        });
      }
    }
  }, [cart.products, cart.cartAuxInfo, paymentMethod, shippingMethod, progressiveDiscountRules]);


  useEffect(() => {
    if (buyerView || logged === 'buyer') {
      dispatch({ type: 'SET_BILLS', bills: null });

      axiosClient
        .post('/payment/bills', { buyerId: buyerView?.id || null }, { withCredentials: true })
        .then((response) => {
          const bills = response.data.bills;
          dispatch({ type: 'SET_BILLS', bills });

          const isAdminPath = location.pathname.startsWith('/admin');
          const isSeller = logged === 'seller' || logged === 'sellerUser';
          const params = new URLSearchParams(window.location.search);

          if (!isAdminPath && isSeller && !params.has('minhas-contas')) {
            const hasOpenBills = bills.some(bill => bill.situacao === 'aberto');
            const hasOverdueBills = bills.some(bill => bill.situacao === 'atrasado');

            if (hasOverdueBills) {
              Modal.error({
                title: 'Restrição Financeira',
                content: 'Esse cliente possui contas atrasadas.',
                footer: (_, { OkBtn, CancelBtn }) => (
                  <>
                    <Button
                      onClick={() => {
                        window.location = '/?minhas-contas';
                      }}
                    >
                      Ver contas
                    </Button>
                    <OkBtn />
                  </>
                ),
              });
            } else if (hasOpenBills) {
              message.warning({
                content: (
                  <>
                    Esse cliente possui contas em aberto.{' '}
                    <Link href={'/?minhas-contas'}>
                      Ver contas
                    </Link>
                  </>
                ),
              });
            }
          }
          else {
            const url = new URL(window.location.href);
            url.searchParams.delete('minhas-contas');
            window.history.replaceState({}, '', url.toString());
          }
        });
    }
  }, [buyerView, logged]);

  useEffect(() => {
    // Faz uma requisição para o arquivo JSON no diretório public
    fetch('/build-hash.json')
      .then(response => response.json())
      .then(data => {
        dispatch({ type: 'SET_BUILD_HASH', buildHash: data.buildHash.substring(0, 5) });
      })
      .catch(error => {
        console.error('Erro ao carregar o hash do build:', error);
      });
  }, []);

  useEffect(() => {
    orderRebuyId && dispatch({ type: 'RESET_CART' })
      && axiosClient
        .post(
          '/cart/get-order',
          { orderId: orderRebuyId },
          { withCredentials: true },
        )
        .then((response) => {

          const { order } = response.data;
          const { products, quantities } = order.order_info;
          const buyerName = order.buyer.tipo_pessoa === 'pf'
            ? (order.buyer.nome_fantasia || order.buyer.nome)
            : order.buyer.razao_social;

          const orderType = order.order_type;
          const sellerDiscountPercentage = order.order_info.seller_discount;
          dispatch({ type: 'SET_ORDER', observations: order.observations });
          dispatch({ type: 'SET_REBUY_INFO', buyerName, orderType });
          sellerDiscountPercentage && dispatch({ type: 'SET_CART', cartAuxInfo: { sellerDiscountPercentage } });
          bulkAddToCart(products, quantities);
        });
  }, [orderRebuyId]);

  useEffect(() => {
    openDB('nupedido', 1).then((db) => {
      db.get('config', 1).then((value) => dispatch({ type: 'SET_SYNC', syncStatus: value.synced ? 'synced' : value.status === 'enabled' ? 'checking' : 'disabled' }));
      dispatch({ type: 'SET_DATABASE', dataBase: db });
    });
  }, []);

  const getProductPrice = (product, qtyInCart) => {
    const priceBulk = product?.product_price_bulk;
    const bulkPrice = priceBulk && Object.entries(priceBulk).reduce((acc, [minQty, price], index, entries) => {
      const nextMinQty = entries[index + 1]?.[0];
      return qtyInCart >= minQty && (!nextMinQty || qtyInCart < nextMinQty) ? price : acc;
    }, null);
    return product.price_modified || Math.min(
      ...[bulkPrice, product.product_price_sale, product.product_price].filter(price => price != null)
    );
  };

  function amount(product) {
    return parseFloat(getProductPrice(product, product.quantity) * product.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  function getFormattedDate() {
    const now = new Date();
    const padZero = (num) => (num < 10 ? '0' : '') + num;
    const formattedDate = `${padZero(now.getDate())}/${padZero(now.getMonth() + 1)}/${now.getFullYear()} `
      + `${padZero(now.getHours())}:${padZero(now.getMinutes())}:${padZero(now.getSeconds())}`;

    return formattedDate;
  }

  function findDiscrepancies(dataBackend, dataFrontend) {
    const idsNotInFrontend = Object.keys(dataBackend).filter((id) => !dataFrontend.some((item) => item.id === parseInt(id))).map((id) => parseInt(id));
    let idsOutdated = dataFrontend
      .filter((item) => dataBackend[item.id])
      .filter((item) => new Date(item.last_modification.replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$2/$1/$3')) < new Date(dataBackend[item.id].replace(/(\d{2})\/(\d{2})\/(\d{2})/, '$2/$1/$3')))
      .map((item) => parseInt(item.id));

    let idsNotInBackend = dataFrontend.filter((item) => !dataBackend[item.id]).map((item) => parseInt(item.id));
    const idsNeedSubmit = dataFrontend.filter((item) => item.needSubmit).map((item) => parseInt(item.id));

    idsOutdated = idsOutdated.filter((id) => !idsNeedSubmit.includes(id));
    idsNotInBackend = idsNotInBackend.filter((id) => !idsNeedSubmit.includes(id));

    return {
      idsNotInFrontend,
      idsOutdated,
      idsNotInBackend,
      idsNeedSubmit,
    };
  }

  async function getItemsByIds(dBStoreName, idsArray) {
    const tx = await dataBase.transaction(dBStoreName, 'readonly');
    let cursor = await tx.store.openCursor();

    const result = [];

    while (cursor) {
      const { key, value } = cursor;

      if (idsArray.includes(key)) {
        result.push(value);
      }

      cursor = await cursor.continue();
    }

    return result;
  }

  function isReachable(url) {
    fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then((resp) => {
        if (resp && (resp.ok || resp.type === 'opaque')) {
          if (syncData.onlineStatus === false) {
            console.log('O aplicativo está Online');
            dispatch({ type: 'SET_SYNC', onlineStatus: true });
          }
        }
      })
      .catch((err) => {
        if (syncData.onlineStatus === true) {
          dispatch({ type: 'SET_SYNC', onlineStatus: false });
          console.log('O aplicativo está Offline');
        }
      });
  }

  useEffect(() => {
    isReachable(`${baseURL}/check`);
    const interval = setInterval(() => isReachable(`${baseURL}/check`), 5000);
    return () => {
      clearInterval(interval);
    };
  }, [syncData.onlineStatus]);

  function checkProductSync() {
    console.log('Checking Products Sync');
    axiosClient.get('/products/check-products-sync', {
      withCredentials: true,
    }).then((response) => {
      dispatch({ type: 'SET_SYNC', productLastMod: response.data.last_modifications });
    });
  }

  function checkBuyerSync() {
    console.log('Checking Buyers Sync');
    axiosClient.get('/buyer/check-buyers-sync', {
      withCredentials: true,
    }).then((response) => {
      dispatch({ type: 'SET_SYNC', buyerLastMod: response.data.last_modifications });
    });
  }

  function checkCartSync() {
    console.log('Checking Carts Sync');
    axiosClient.get('/cart/check-carts-sync', {
      withCredentials: true,
    }).then((response) => {
      dispatch({ type: 'SET_SYNC', cartLastMod: response.data.last_modifications });
    });
  }

  function checkOrderSync() {
    console.log('Checking Order Sync');
    getAllData('orders').then((orders) => {
      dispatch({
        type: 'SET_SYNC',
        orderDiscrepancies: {
          ...syncData.orderDiscrepancies,
          idsNeedSubmit: orders.map((order) => order.id),
          idsNotInFrontend: [],
          idsOutdated: [],
        },
      });
    });
  }

  function checkConfigSync() {
    console.log('Checking Config Sync');
    axiosClient.get('/shipping/get', {
      withCredentials: true,
    }).then((response) => {
      updateData({ id: 3, ...response.data }, 'config');
    });
    axiosClient.get('/payment/get', {
      withCredentials: true,
    }).then((response) => {
      updateData({ id: 4, ...response.data }, 'config');
    });
  }

  useEffect(() => {
    if (syncData.onlineStatus && syncData.syncStatus === 'synced') {
      dispatch({ type: 'SET_SYNC', syncStatus: 'checking' });
      buyerView && axiosClient.post(
        '/cart/set-buyer-view',
        {
          buyerViewId: buyerView.id,
        },
        {
          withCredentials: true,
        },
      );
    }
  }, [syncData.onlineStatus]);

  useEffect(() => {
    if ((logged === 'seller' || logged === 'sellerUser')
      && dataBase
      && (syncData.syncStatus === 'checking' || syncData.syncStatus === 'synced')
      && syncData.onlineStatus) {
      dataBase.put('config', { id: 1, status: 'enabled', synced: false });
      checkOrderSync();
      checkProductSync();
      checkBuyerSync();
      checkCartSync();
      checkConfigSync();
    }

    if (dataBase && syncData.syncStatus === 'disabled') {
      dataBase.put('config', { id: 1, status: 'disabled', synced: false });
    }
  }, [syncData.onlineStatus, syncData.syncStatus, dataBase, logged]);

  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.productLastMod) {
      getAllData('products').then((productsInFrontend) => {
        dispatch({ type: 'SET_SYNC', productDiscrepancies: findDiscrepancies(syncData.productLastMod, productsInFrontend) });
      });
    }
  }, [syncData.productLastMod, dataBase]);

  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.buyerLastMod) {
      getAllData('buyers').then((buyersInFrontend) => {
        dispatch({ type: 'SET_SYNC', buyerDiscrepancies: findDiscrepancies(syncData.buyerLastMod, buyersInFrontend) });
      });
    }
  }, [syncData.buyerLastMod, dataBase]);

  useEffect(() => {
    if (dataBase && syncData.onlineStatus && syncData.cartLastMod) {
      getAllData('carts').then((cartsInFrontend) => {
        dispatch({ type: 'SET_SYNC', cartDiscrepancies: findDiscrepancies(syncData.cartLastMod, cartsInFrontend) });
      });
    }
  }, [syncData.cartLastMod, dataBase]);

  useEffect(() => {
    if (
      Object.values(syncData.productDiscrepancies).every((value) => Array.isArray(value))
      && Object.values(syncData.buyerDiscrepancies).every((value) => Array.isArray(value))
      && Object.values(syncData.cartDiscrepancies).every((value) => Array.isArray(value))
      && Object.values(syncData.orderDiscrepancies).every((value) => Array.isArray(value))
    ) {
      const productSynced = Object.values(syncData.productDiscrepancies).every((value) => value.length === 0);

      const buyerSynced = Object.values(syncData.buyerDiscrepancies).every((value) => value.length === 0);

      const cartSynced = Object.values(syncData.cartDiscrepancies).every((value) => value.length === 0);

      if (productSynced !== syncData.productSynced || buyerSynced !== syncData.buyerSynced || cartSynced !== syncData.cartSynced) {
        dispatch({
          type: 'SET_SYNC', productSynced, buyerSynced, cartSynced,
        });
      }

      if (syncData.syncStore === null) {
        if (syncData.orderDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'orders', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else {
          dispatch({
            type: 'SET_SYNC', productSynced: true, buyerSynced: true, cartSynced: true,
          });
        }
      } else if (syncData.syncStore === 'products') {
        if (syncData.productDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.productDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'products', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else {
          dispatch({
            type: 'SET_SYNC', productSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0,
          });
        }
      } else if (syncData.syncStore === 'buyers') {
        if (syncData.buyerDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.buyerDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'buyers', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else {
          dispatch({
            type: 'SET_SYNC', buyerSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0,
          });
        }
      } else if (syncData.syncStore === 'carts') {
        if (syncData.cartDiscrepancies.idsNotInFrontend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNotInFrontend', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsOutdated.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsOutdated', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsNeedSubmit.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNeedSubmit', syncIterationStep: 0,
          });
        } else if (syncData.cartDiscrepancies.idsNotInBackend.length > 0) {
          dispatch({
            type: 'SET_SYNC', syncStore: 'carts', syncStep: 'idsNotInBackend', syncIterationStep: 0,
          });
        } else {
          dispatch({
            type: 'SET_SYNC', cartSynced: true, syncStore: null, syncStep: null, syncIterationStep: 0,
          });
        }
      }
    }
  }, [syncData.syncStore, syncData.productDiscrepancies, syncData.buyerDiscrepancies, syncData.cartDiscrepancies]);

  useEffect(() => {
    if (syncData.syncStore === 'orders') {
      syncData.syncStatus !== 'syncing' && dispatch({ type: 'SET_SYNC', syncStatus: 'syncing' });

      if (syncData.syncStep === 'idsNeedSubmit') {
        getItemsByIds('orders', syncData.orderDiscrepancies.idsNeedSubmit).then((orders) => {
          orders.forEach((orderData) => {
            axiosClient
              .post('/cart/order', orderData, { withCredentials: true })
              .then((response) => {
                if (response.data.status === 'Success') {
                  dataBase.delete('orders', orderData.id);
                }
              });
          });
        }).then(
          dispatch({ type: 'SET_SYNC', orderDiscrepancies: { ...syncData.orderDiscrepancies, idsNeedSubmit: [] } }),
        );
      }
    } else if (syncData.syncStore === 'products') {
      syncData.syncStatus !== 'syncing' && dispatch({ type: 'SET_SYNC', syncStatus: 'syncing' });

      if (syncData.syncStep === 'idsNotInBackend') {
        syncData.productDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('products', itemId));
        dispatch({ type: 'SET_SYNC', productDiscrepancies: { ...syncData.productDiscrepancies, idsNotInBackend: [] } });
      }

      if (syncData.syncStep === 'idsNotInFrontend' || syncData.syncStep === 'idsOutdated') {
        const funcData = syncData.syncStep === 'idsNotInFrontend' ? addData : syncData.syncStep === 'idsOutdated' && updateData;
        const searchIds = syncData.syncStep === 'idsNotInFrontend' ? syncData.productDiscrepancies.idsNotInFrontend : syncData.syncStep === 'idsOutdated' && syncData.productDiscrepancies.idsOutdated;

        const searchUrl = '/products/search';
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          retrieveImages: true,
          searchIds,
        }, { withCredentials: true }).then((response) => {
          response.data.products.forEach((item, i) => funcData(item, 'products', response.data.images[i]));
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === 'idsNotInFrontend' && dispatch({ type: 'SET_SYNC', productDiscrepancies: { ...syncData.productDiscrepancies, idsNotInFrontend: [] } });
            syncData.syncStep === 'idsOutdated' && dispatch({ type: 'SET_SYNC', productDiscrepancies: { ...syncData.productDiscrepancies, idsOutdated: [] } });
          } else {
            syncData.syncDrawer && dispatch({ type: 'SET_SYNC', syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages });
          }
        });
      } else if (syncData.syncStep === 'idsNeedSubmit') {
        // remover do indexDb
      }
    } else if (syncData.syncStore === 'buyers') {
      syncData.syncStatus !== 'syncing' && dispatch({ type: 'SET_SYNC', syncStatus: 'syncing' });

      if (syncData.syncStep === 'idsNotInBackend') {
        syncData.buyerDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('buyers', itemId));
        dispatch({ type: 'SET_SYNC', buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsNotInBackend: [] } });
      }

      if (syncData.syncStep === 'idsNotInFrontend' || syncData.syncStep === 'idsOutdated') {
        const funcData = syncData.syncStep === 'idsNotInFrontend' ? addData : syncData.syncStep === 'idsOutdated' && updateData;
        const searchIds = syncData.syncStep === 'idsNotInFrontend' ? syncData.buyerDiscrepancies.idsNotInFrontend : syncData.syncStep === 'idsOutdated' && syncData.buyerDiscrepancies.idsOutdated;
        const searchUrl = '/auth/search/buyer';
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          searchIds,
        }, { withCredentials: true }).then((response) => {
          response.data.buyers.forEach((item) => funcData(item, 'buyers'));
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === 'idsNotInFrontend' && dispatch({ type: 'SET_SYNC', buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsNotInFrontend: [] } });
            syncData.syncStep === 'idsOutdated' && dispatch({ type: 'SET_SYNC', buyerDiscrepancies: { ...syncData.buyerDiscrepancies, idsOutdated: [] } });
          } else {
            syncData.syncDrawer && dispatch({ type: 'SET_SYNC', syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages });
          }
        });
      } else if (syncData.syncStep === 'idsNeedSubmit') {
        // remover do indexDb
      }
    } else if (syncData.syncStore === 'carts') {
      syncData.syncStatus !== 'syncing' && dispatch({ type: 'SET_SYNC', syncStatus: 'syncing' });

      if (syncData.syncStep === 'idsNotInBackend') {
        syncData.cartDiscrepancies.idsNotInBackend.forEach((itemId) => dataBase.delete('carts', itemId));
        dispatch({ type: 'SET_SYNC', cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNotInBackend: [] } });
      }

      if (syncData.syncStep === 'idsNotInFrontend' || syncData.syncStep === 'idsOutdated') {
        const funcData = syncData.syncStep === 'idsNotInFrontend' ? addData : syncData.syncStep === 'idsOutdated' && updateData;
        const searchIds = syncData.syncStep === 'idsNotInFrontend' ? syncData.cartDiscrepancies.idsNotInFrontend : syncData.syncStep === 'idsOutdated' && syncData.cartDiscrepancies.idsOutdated;
        const searchUrl = '/cart/search';
        axiosClient.post(searchUrl, {
          page: syncData.syncIterationStep,
          perPage: 10,
          searchIds,
        }, { withCredentials: true }).then((response) => {
          response.data.carts.forEach((item) => funcData(item, 'carts'));
          if (response.data.actual_page === response.data.total_pages) {
            syncData.syncStep === 'idsNotInFrontend' && dispatch({ type: 'SET_SYNC', cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNotInFrontend: [] } });
            syncData.syncStep === 'idsOutdated' && dispatch({ type: 'SET_SYNC', cartDiscrepancies: { ...syncData.cartDiscrepancies, idsOutdated: [] } });
          } else {
            syncData.syncDrawer && dispatch({ type: 'SET_SYNC', syncIterationStep: response.data.actual_page + 1, syncIterationTotal: response.data.total_pages });
          }
        });
      } else if (syncData.syncStep === 'idsNeedSubmit') {
        getItemsByIds('carts', syncData.cartDiscrepancies.idsNeedSubmit).then((carts) => {
          carts.forEach((cart) => {
            getItemsByIds('products', cart.products_ids).then((products) => {
              const productsWithInfos = products.sort((a, b) => cart.products_ids.indexOf(a.id) - cart.products_ids.indexOf(b.id)).map((product, index) => ({ ...product, quantity: cart.quantities[index] }));

              getSingleData('buyers', cart.buyer_id).then((buyer) => sendCart(productsWithInfos, buyer, cartAuxInfo, syncData).then((response) => {
                dataBase.delete('carts', cart.id);
              })).then(
                dispatch({ type: 'SET_SYNC', cartDiscrepancies: { ...syncData.cartDiscrepancies, idsNeedSubmit: [] } }),
              );
            });
          });
        });
      }
    }
  }, [syncData.syncIterationStep, syncData.syncStore, syncData.syncStep, syncData.syncDrawer]);

  async function getAllData(dBStoreName) {
    const tx = dataBase.transaction(dBStoreName, 'readonly');
    const store = tx.objectStore(dBStoreName);

    return await store.getAll();
  }

  async function getDataFromCursor(dBStoreName) {
    const tx = await dataBase.transaction(dBStoreName, 'readonly');
    const cursor = await tx.store.openCursor();
    return cursor.value;
  }

  async function getSingleData(dBStoreName, idx) {
    const value = await dataBase.get(dBStoreName, idx);
    return value;
  }

  async function addData(dbData, dBStoreName, fileData = null) {
    try {
      const tx = await dataBase.transaction(dBStoreName, 'readwrite');
      const store = tx.objectStore(dBStoreName);
      store.add(dbData);
      await tx.done;
      fileData && localStorage.setItem(`${dBStoreName}_${dbData.id}`, fileData);
    } catch (error) {
      console.error('Erro: ', error);
    }
  }

  async function updateData(data, dBStoreName, fileData = null) {
    try {
      dataBase.put(dBStoreName, data);
    } catch (error) {
      console.error('Erro: ', error);
    }
  }

  function extractProductInfo(products) {
    const result = {
      products_ids: [],
      prices: [],
      prices_modified: [],
      quantities: [],
    };

    for (const product of products) {
      // Verifica se o objeto possui os campos necessários
      const productId = product.id ? product.id : null;
      const price = product.product_price ? product.product_price : null;
      const priceModified = product.price_modified ? product.price_modified : null;
      const quantity = product.quantity ? product.quantity : null;

      // Adiciona os valores ao resultado
      result.products_ids.push(productId);
      result.prices.push(price);
      result.prices_modified.push(priceModified);
      result.quantities.push(quantity);
    }

    return result;
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('state') && params.has('code')) {
      const state = atob(params.get('state')).split(';');
      let url;
      if (state[0] === 'nuvemshop') {
        url = `${state[1]}?code=${params.get('code')}&integration=${state[0]}`;
      } else {
        url = `${state[1]}/admin/configuracoes?code=${params.get('code')}&integration=${state[0]}`;
      }
      window.location.replace(url);
    }
  }, []);

  useEffect(() => {
    if (dataBase && syncData.productSynced && syncData.buyerSynced && syncData.cartSynced && syncData.syncStatus !== 'disabled') {
      dispatch({ type: 'SET_SYNC', syncStatus: 'synced' });
      dataBase.put('config', { id: 1, status: 'enabled', synced: true });
    }
  }, [dataBase, syncData.syncStatus, syncData.productSynced, syncData.buyerSynced, syncData.cartSynced]);

  useEffect(() => {
    if (buyerView || logged === 'buyer') {
      axiosClient
        .post('/products/rules/applied', { buyerViewId: buyerView?.id || null, ruleAction: 'progressiveDiscount' }, { withCredentials: true })
        .then((response) => {
          const rules = response.data?.applied_product_rules;
          dispatch({ type: 'SET_PROGRESSIVE_DISCOUNT_RULES', progressiveDiscountRules: rules });
        });
    }
  }, [buyerView]);

  async function sendCart(products, buyer, cartAuxInfo, syncData, cancelToken) {
    dispatch({ type: 'CART_UPDATED', updated: false });
    products = products.map(({ id, quantity, price_modified }) => ({
      id,
      quantity,
      ...(price_modified !== undefined && { price_modified }),
    }));
    if (syncData.onlineStatus) {
      axiosClient
        .post(
          '/cart/add',
          JSON.stringify({
            products,
            buyerId: buyer ? buyer.id : null,
            cartAuxInfo,
          }),
          {
            withCredentials: true,
            cancelToken: cancelToken.token,
          },
        )
        .then((response) => {
          if (syncData.syncStatus === 'synced') {
            const cartData = products.reduce((acc, curr) => {
              acc.products_ids.push(curr.id);
              acc.prices_modified.push(curr.price_modified || null);
              acc.quantities.push(curr.quantity || null);
              return acc;
            }, { products_ids: [], prices_modified: [], quantities: [] });
            const currentDate = getFormattedDate();
            updateData({
              id: buyer.cart_id, buyer_id: buyer.id, last_modification: currentDate, ...cartData,
            }, 'carts');
          }
          dispatch({ type: 'SET_CART', returned: true });
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log('Previous request canceled', error.message);
          }
        });
    } else if (syncData.syncStatus === 'synced') {
      const currentDate = getFormattedDate();
      const cartData = {
        id: buyer.cart_id, buyer_id: buyer.id, seller_id: buyer.seller_id, last_modification: currentDate, needSubmit: true, ...extractProductInfo(products),
      };
      dataBase.put('carts', cartData);
      dispatch({ type: 'CART_RETURNED', returned: true });
    }
  }

  const sendCartHandler = useCallback(debounce((products, buyer, cartAuxInfo, syncData, cancelToken) => sendCart(products, buyer, cartAuxInfo, syncData, cancelToken), 600), []);

  const cancelTokenRef = useRef(null);

  useEffect(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation canceled due to new request.');
    }
    cancelTokenRef.current = axios.CancelToken.source();

    if (cart.retrieved && cart.updated && buyerView) {
      if (!orderRebuyId) {
        sendCartHandler(cart.products, buyerView, cart.cartAuxInfo, syncData, cancelTokenRef.current);
      } else {
        console.log('Estamos editando o pedido...');
      }
    }

    return () => {
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel('Component unmounted.');
      }
    };
  }, [cart.products, cart.cartAuxInfo, buyerView, syncData.syncStatus, syncData.onlineStatus]);

  useEffect(
    () => {
      cart.updated === false
        && cart.returned
        && cart.goToCheckout
        && window.location.replace('/checkout');
    },
    [cart.goToCheckout, cart.returned, cart.updated],
  );



  useEffect(() => {
    if (!buyerViewRequested.current
      && (logged === 'buyer' || logged === 'seller' || logged === 'sellerUser')
      && (pathName === sellerData.pathName || pathName === 'checkout')
      && (buyerView === null)
    ) {
      if (syncData.onlineStatus) {
        buyerViewRequested.current = true;
        axiosClient
          .get('/cart/get-buyer-view', { withCredentials: true })
          .then((response) => {
            dispatch({
              type: 'SET_BUYER_VIEW',
              buyerView: response.data.buyer_view,
            });
            dispatch({ type: 'ACTIVE_CEP', value: response.data.buyer_view.cep, uf: response.data.buyer_view.uf });

            dataBase && dataBase.put('config', { id: 2, buyerView: response.data.buyer_view });
          });
      } else if (dataBase) {
        getSingleData('config', 2).then((value) => {
          if (value.buyerView) {
            buyerViewRequested.current = true;
            dispatch({
              type: 'SET_BUYER_VIEW',
              buyerView: value.buyerView,
            });
          } else if (syncData.syncStatus === 'synced') {
            getDataFromCursor('buyers').then((buyerViewValue) => {
              buyerViewRequested.current = true;
              dispatch({
                type: 'SET_BUYER_VIEW',
                buyerView: buyerViewValue,
              });
              dataBase.put('config', { id: 2, buyerView: buyerViewValue });
            });
          }
        });
      }
    }
  }, [dataBase, logged, pathName, sellerData, syncData.onlineStatus, syncData.syncStatus]);


  useEffect(() => {
    if (cart.updated) {
      stateMessage.messageType === 'success'
        && message.success(stateMessage.messageText, 4);
      stateMessage.messageType === 'error'
        && message.error(stateMessage.messageText, 4);
      stateMessage.messageType === 'warning'
        && message.warning(stateMessage.messageText, 4);
    }
  }, [stateMessage]);


  useEffect(() => {
    if (!dataBase && (logged !== 'seller' && logged !== 'sellerUser')) return;

    const fetchData = async () => {
      try {
        const response = await axiosClient.get('check-pathname', {
          params: { pathName, domainName },
          withCredentials: true,
        });

        const responseData = response.data;
        dispatch({
          type: 'SET_SELLER',
          ...responseData,
          orderOutOfStock: responseData.configuration?.order_out_of_stock ?? false,
          url: responseData.logo_image ? responseData.logo_image.url : false,
          pathName,
        });

        if (logged === 'seller' || logged === 'sellerUser') {
          updateData({ id: 5, ...responseData }, 'config');
        }

        if (responseData.status === 'Success' && responseData.nome_fantasia) {
          setPageTitle(responseData.nome_fantasia);
        }

        setCheckSeller(true);
      } catch {
        if (logged === 'seller' || logged === 'sellerUser') {
          const fallbackData = await getSingleData('config', 5);
          dispatch({
            type: 'SET_SELLER',
            ...fallbackData,
            url: fallbackData.logo_image ? fallbackData.logo_image.url : false,
            pathName,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [logged, dataBase]);


  useEffect(() => {
    checkToken();
  }, [logged]);

  useEffect(() => {
    document.body.style.backgroundColor = token.colorBgContainer
  }, [token])

  useEffect(() => {
    if (sellerData?.configuration) {
      const {
        colorBgBase = null,
        colorPrimary = null,
        colorTextBase = null,
        colorInfo = null,
        isDark = null
      } = sellerData.configuration;
      if (colorBgBase || colorPrimary || colorTextBase || colorInfo || isDark !== null) {
        dispatch({
          type: 'SET_THEME',
          colorBgBase,
          colorPrimary,
          colorTextBase,
          colorInfo,
          isDark
        });
      }
    }
  }, [sellerData.configuration])


  useEffect(() => {
    dataBase && getSingleData('config', 5).then((data) => {
      if (data?.configuration) {
        const {
          colorBgBase = null,
          colorPrimary = null,
          colorTextBase = null,
          colorInfo = null,
          isDark = null
        } = data.configuration;
        if (colorBgBase || colorPrimary || colorTextBase || colorInfo || isDark !== null) {
          dispatch({
            type: 'SET_THEME',
            colorBgBase,
            colorPrimary,
            colorTextBase,
            colorInfo,
            isDark
          });
        }
      }
    });
  }, [dataBase]);

  return (
    <>
      {loading || logged === 'notChecked' || !dataBase ? (
        <div style={{ textAlign: 'center', padding: '30px 50px' }}>
          <Spin size="large" />
        </div>
      ) : (
        sellerData.blocked !== true ?
          <>
            {checkSeller && (
              <HelmetProvider>
                <Helmet>
                  <title>{`${pageTitle} - Pedido Online`}</title>
                </Helmet>
              </HelmetProvider>
            )}
            <Routes>
              {/* Rotas para vendedores */}
              {(logged === 'seller' || logged === 'sellerUser') && (
                <>
                  <Route path="admin/*" element={<Admin />} />
                  <Route path="checkout" element={<Checkout />} />
                  <Route path={'/checkout/:orderRebuyId'} element={<Checkout />} />
                  <Route path={'/pedidos/:orderRebuyId'} element={<Catalogo />} />
                  <Route path={'/orcamentos/:orderRebuyId'} element={<Catalogo />} />
                  <Route path='*' element={<Catalogo />} />
                </>
              )}

              {/* Rota para compradores */}
              {logged === 'buyer' && (
                <>
                  <Route path="checkout" element={<Checkout />} />
                  <Route path="*" element={<Catalogo />} />
                </>
              )}

              {/* Login de vendedor */}
              {logged === false && checkSeller && (
                <Route path="admin/*" element={
                  <>
                    <div
                      style={{
                        backgroundImage: 'url(/background.gif)',
                        backgroundSize: '100% 100%',
                        height: '100vh',
                        width: '100%',
                      }}
                    >
                      <SellerLogin domainName={domainName} dataBase={dataBase} />
                    </div>
                  </>
                } />
              )}

              {/* Login de comprador */}
              {logged === false && checkSeller && (
                <Route path="*" element={<BuyerLogin pathName={pathName} domainName={domainName} dataBase={dataBase} />} />
              )}

              {/* Carregando estado padrão */}
              <Route
                path="*"
                element={
                  <div style={{ textAlign: 'center', padding: '30px 50px' }}>
                    <Spin size="large" />
                  </div>
                }
              />
            </Routes>
          </> :
          <Result
            status='403'
            title="Sistema Indisponível"
            subTitle="Entre em contato com o suporte para regularizar seu acesso."
          >
          </Result>
      )}
    </>
  );

}